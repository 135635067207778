import Section from "../../../section/Section";

export default function OurHistorySection() {
	return (
		<Section className="our-history-section text-center pt-17 pt-md-15 pb-12 pb-md-19 mb-7 mb-md-25 border-bottom border-dark">
			<p className="m-0 font-size-lg text-underline pb-6">Our history</p>
			<h2 className="mx-auto pb-8 pb-md-9" style={{ maxWidth: 498 }}>
				Providing the spark of ambition since 2018
			</h2>
			<div className="mx-auto mb-7 mb-md-10 border py-8 py-md-9 px-4 px-md-12 border-dark" style={{ maxWidth: 600 }}>
				<p className="m-0 font-size-lg pb-5">
					Stay Nimble has been supporting working adults to develop in their careers since 2018, and thousands of people
					have worked with our coaches to successfully learn new skills, take on new responsibilities and realise their
					ambitions.
				</p>
				<p className="m-0 font-size-lg pb-5">
					Today, we’re recognised as an industry leader in career development practices and technologies.
				</p>
			</div>
		</Section>
	);
}
