import Section from "../../../section/Section";
import { useEffect, useState } from "react";
import axios from "axios";
import { formatMoney } from "../../../../../../src/_helpers/_utilities";
import UKSVBLogo from "@images/v2/social-value-bank.png";
import Image from "../../../Image";

function Box({ text, value, bgColor, textClass = "" }) {
	return (
		<div
			className={`d-flex flex-column flex-md-row social-impact-box justify-content-between border border-dark py-5 py-md-8 pl-6 pr-10 pl-md-8 pr-md-13 mb-4 text-left ${bgColor}`}
		>
			<p className={`pb-2 pb-md-0 stats-text ${textClass}`}>{text}</p>
			<p className="stats-value">{value}</p>
		</div>
	);
}

export default function SocialImpactSection() {
	const [socialValue, setSocialValue] = useState({});
	useEffect(() => {
		axios.get("https://api.staynimble.co.uk/api/public/social-value").then((result) => {
			setSocialValue(result.data);
		});
	}, []);

	const { total, positiveWellBeingOutcomeCount, employmentOutcomeCount, userCount } = socialValue;

	return (
		<Section className="social-impact-section text-center pt-13 pt-md-20 pb-8 pb-md-20 border-top border-dark">
			<div className="d-flex flex-column align-items-center">
				<p className="font-size-lg text-underline pb-8 pb-md-12">Our social impact</p>
				<p className="pb-8 pb-md-6 font-size-xl" style={{ maxWidth: 584 }}>
					We’re driven by the impact we can have on our members and their communities. To date, we have evidenced social
					value amounting to:
				</p>
				{total && <p className="font-size-10xl pb-8 pb-md-6">£{formatMoney(total, 0)}</p>}
				<p className="font-size-xl pb-8 pb-md-15" style={{ maxWidth: 584 }}>
					Our Social Value total is the financial quantification of the positive outcomes that our programmes have on
					our members and society. All our social value calculations are verified by the UK Social Value Bank.
				</p>
				<Box
					text="Members with positive employment & training outcomes:"
					value={formatMoney(employmentOutcomeCount || 0, 0)}
					bgColor="bg-pink"
				/>
				<Box
					text="Members with positive wellbeing outcomes:"
					value={formatMoney(positiveWellBeingOutcomeCount || 0, 0)}
					bgColor="bg-red"
				/>
				<Box
					text="Registered members:"
					value={formatMoney(userCount || 0, 0)}
					bgColor="bg-purple"
					textClass="small-stats-text"
				/>
				<Box text="Service Rating:" value="4.9/5" bgColor="bg-mid-green" textClass="small-stats-text" />
				<Image className="pt-10 pt-md-12" src={UKSVBLogo} width={184} height={105} alt="UK Social Value Bank" />
				<p className="text-underline pt-8 pt-md-6 pb-0 pb-md-9">Learn more about the UK Social Value Bank</p>
			</div>
		</Section>
	);
}
