import Document from "../../components/v2/Document";
import WhoWeAreDocument from "../../components/_pages/v2/who-we-are/WhoWeAreDocument";

const WhoWeAre = () => {
	return (
		<Document
			title="A Social Enterprise Uplifting Workforces and Communities"
			className="page-who-we-are-v2"
			ogData={{
				title: "Stay Nimble. Our mission is to answer the question. - Stay Nimble",
				description:
					"Unlock your organiaation's potential through Stay Nimble's coaching solutions. Our business model delivers measurable value while creating brighter futures.",
				image: "https://s3.eu-west-2.amazonaws.com/static.staynimble.co.uk/assets/open-graph/preview.jpg",
				url: "/who-we-are/",
			}}
		>
			<WhoWeAreDocument />
		</Document>
	);
};

export default WhoWeAre;
