import Hero from "../../../v2/hero/Hero";

import heroImage from "@images/v2/who-we-are/hero-2.jpg";
import heroMdImage from "@images/v2/who-we-are/hero-md.png";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import useTypeForm from "../../../../hooks/useTypeForm";

const WhoWeAreHero = () => {
	const { event } = useAnonymousDataLayer();
	const freeConsultationForm = useTypeForm("R6iu6OZO");

	return (
		<Hero
			upperHeader="The Stay Nimble difference"
			title="Resilience woven into the fabric of your organisation"
			subtitle="We’re an award-winning social enterprise bringing plug-in, and on-demand, qualified career development to everyone."
			heroImage={heroImage}
			heroMdImage={heroMdImage}
		>
			<div className="d-flex flex-column flex-md-row">
				<Button
					onClick={() => {
						event("Book free consultation", "coaching", "Organisations interaction");
						freeConsultationForm.open();
					}}
					className="btn-cornered btn-white btn-hover-light-green"
				>
					Book a free consultation
				</Button>
			</div>
		</Hero>
	);
};
export default WhoWeAreHero;
