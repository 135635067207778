import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import FeaturedIn from "../../../v2/featured-in/FeaturedIn";
import AwardWinning from "../../../v2/AwardWinning";
import MotionSection from "../../../v2/MotionSection";
import WhoWeAreHero from "./WhoWeAreHero";
import OurHistorySection from "./OurHistorySection";
import BusinessImpactSection from "./BusinessImpactSection";
import ImpactSection from "../../../v2/ImpactSection";
import clicksAndMortar from "@images/v2/clicks-and-mortar.png";
import elevateImage from "@images/v2/for-organisations/elevate.png";
import ReferenceSection from "../../../v2/ReferenceSection";
import SocialEnterpriseSection from "../../../v2/SocialEnterpriseSection";
import QualifiedSection from "../../../v2/QualifiedSection";
import SocialImpactSection from "./SocialImpactSection";

const WhoWeAreDocument = () => {
	const { event } = useAnonymousDataLayer();

	return (
		<>
			<WhoWeAreHero />
			<FeaturedIn />
			<OurHistorySection />
			<BusinessImpactSection />
			<ImpactSection showHeader={false} />
			<ReferenceSection
				title="“Stay Nimble isn’t just an investment in your team; it’s an investment in the future of your business.”"
				name="Carey Benn"
				jobTitle="Founder & CEO"
				mainImage={elevateImage}
				logo={clicksAndMortar}
				marginTop={false}
			/>

			<SocialImpactSection />

			<SocialEnterpriseSection bgColor="bg-mid-green" />

			<QualifiedSection />
			<AwardWinning />
			<MotionSection />
		</>
	);
};

export default WhoWeAreDocument;
